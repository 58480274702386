import React from "react";
import { Box, Paragraph } from "grommet";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import { Container, Section } from "../elements/Layout";
import { Title } from "../elements/Text";
import { LinkButton } from "../elements/Button";
import { ImageBox } from "../elements/ImageBox";
import { SkillItem, Skills } from "../components/Skills";
import theme from "../../config/theme";
import { EnvelopeIcon, PhoneIcon, PinIcon } from "../styles/icons";
import SEO from "../components/SEO";
import { CONTACT_PAGE } from "../../config/routes";
import { FadeInLeft } from "../elements/Animations";

const AboutPage = styled.div`
  .hero-section {
    .title {
      align-self: center;
    }

    .large-image {
      display: none;
    }

    .button-wrapper {
      justify-content: center;
    }
  }

  .d-none {
    display: none;
  }

  .gap {
    width: 40px;
  }

  @media (min-width: ${theme.breakpoints.large}) {
    .hero-section {
      .image-wrapper {
        display: none;
      }

      .large-image {
        display: flex;
      }

      .title {
        align-self: flex-start;
      }

      .title + .image-wrapper {
        display: none;
      }

      .button-wrapper {
        justify-content: flex-start;
      }
    }

    .d-lg-block {
      display: flex;
    }

    .d-lg-none {
      display: none;
    }
  }
`;

const StyledSection = styled(Section)`
  padding: 40px 0;

  .bio-card-wrapper {
    padding: 15px;
    margin-bottom: 100px;
  }

  @media (min-width: ${theme.breakpoints.large}) {
    padding: 80px 0;

    .bio-card-wrapper {
      box-shadow: 0 4px 25px 5px rgb(0 0 0 / 60%);
      padding: 80px;
      margin-bottom: 60px;
    }

    p {
      max-width: 580px;
    }
  }

  .skills-row {
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
`;

const BioCard = styled(Box)`
  h6 {
    font-size: 22px;
    margin-bottom: 20px;
  }
`;

const LeftBox = styled(Box)`
  h4 {
    font-size: 30px;
    margin-bottom: 8px;
  }

  p {
    font-size: 22px;
  }

  @media (min-width: ${theme.breakpoints.medium}) {
    min-width: 340px;
  }
`;

const Avatar = styled(Box)`
  margin: 0 auto 20px auto;
  width: 160px;

  @media (min-width: ${theme.breakpoints.medium}) {
    width: 200px;
  }
`;

const RightBox = styled(Box)`
  width: 100%;

  > div {
    width: 100%;
    max-width: 580px;
  }

  .icon {
    margin-right: 20px;
    margin-top: 3px;
    width: 21px;
    height: 21px;

    i {
      display: block;
    }
  }

  .info-item {
    color: ${theme.colors.brand};
    margin-bottom: 10px;
    max-width: 580px;
  }

  .education-item {
    span {
      display: block;
      color: ${theme.colors.light};
      font-weight: 200;
    }
  }

  p {
    margin: 0;
  }

  @media (min-width: ${theme.breakpoints.large}) {
    align-items: center;
  }
`;

const About = ({ location }) => (
  <AboutPage>
    <SEO title="About" location={location} />
    <StyledSection offsetTop={false} offsetBottom={false} className="hero-section">
      <Container>
        <Box direction="row-responsive" align="center" basis="full">
          <FadeInLeft>
            <Box>
              <Title className="title" level="1" decorator margin={{ bottom: "medium" }}>
                About team
              </Title>

              <Box className="image-wrapper">
                <StaticImage src="../images/team.jpg" alt="about us" placeholder="none" quality={90} />
              </Box>

              <Paragraph margin={{ vertical: "large" }}>
                Our small, yet very ambitious team consists of two people - Ondrej and Aliona. Ondrej is in charge of
                development, both frontend and backend, and Aliona is responsible for creating designs and visuals. We
                perfectly understand each other and always come to a common decision which satisfies not only us, but
                our customers as well.
              </Paragraph>

              <Box direction="row" flex={false} className="button-wrapper">
                <LinkButton route={CONTACT_PAGE}>Contact us</LinkButton>
              </Box>
            </Box>
          </FadeInLeft>

          <div className="gap d-none d-lg-block" />

          <ImageBox className="large-image" align="end" maxWidth="600px">
            <StaticImage src="../images/team.jpg" alt="about us" placeholder="none" quality={90} />
          </ImageBox>
        </Box>
      </Container>
    </StyledSection>

    <StyledSection>
      <Container>
        <Box justify="center" width="full" className="bio-card-wrapper">
          <BioCard gap="large" direction="row-responsive">
            <LeftBox gap="xlarge">
              <Box align="center">
                <Avatar>
                  <StaticImage src="../images/paco.png" alt="paco" placeholder="none" quality={90} />
                </Avatar>
                <Title level="4">Ondrej Pacas</Title>
                <p>Fullstack developer</p>
              </Box>

              <Box>
                <Title level={6}>Skills</Title>
                <Box gap="large" justify="between" className="skills-row">
                  <Skills>
                    <SkillItem label="Javascript" points={9} />
                    <SkillItem label="ReactJS" points={9} />
                    <SkillItem label="PHP" points={9} />
                  </Skills>

                  <Skills>
                    <SkillItem label="HTML & CSS" points={10} />
                    <SkillItem label="WordPress" points={9} />
                    <SkillItem label="MySQL" points={8} />
                  </Skills>
                </Box>
              </Box>
            </LeftBox>
            <RightBox gap="large">
              <Box margin={{ bottom: "24px" }}>
                <Box direction="row" className="info-item">
                  <div className="icon">
                    <PinIcon />
                  </div>
                  Brno, Czech republic
                </Box>
                <Box direction="row" className="info-item">
                  <div className="icon">
                    <PhoneIcon />
                  </div>
                  <Box direction="column">
                    +421 908 883 366
                    <br />
                    +420 603 330 571
                  </Box>
                </Box>
                <Box direction="row" className="info-item">
                  <div className="icon">
                    <EnvelopeIcon />
                  </div>
                  <Box direction="column">
                    develop&#109;&#101;nt&#64;ti&#116;a&#110;d&#101;sig&#110;&#46;eu
                    <br />
                    o&#110;dropac&#97;s&#64;gm&#97;i&#108;&#46;com
                  </Box>
                </Box>
              </Box>
              <Box margin={{ bottom: "24px" }}>
                <Title level={6}>About me</Title>
                <Paragraph>
                  Creation of a software which serves its purpose with grace is my mission. I care about good
                  architecture and a user-friendly interface with a sense for detail. Passionate about learning,
                  I&nbsp;always find ways to refine my craft in order to deliver a perfect product, whether it is a
                  simple website or a complex app.
                </Paragraph>
              </Box>
              <Box>
                <Title level={6}>Education</Title>
                <Paragraph className="education-item">
                  VUT FEKT Brno, Teleinformatics
                  <span>
                    Bachelor’s degree
                    <br />
                    <br />
                    2015 – 2018
                  </span>
                </Paragraph>
              </Box>
            </RightBox>
          </BioCard>
        </Box>
        <Box justify="center" width="full" className="bio-card-wrapper">
          <BioCard gap="large" direction="row-responsive">
            <LeftBox gap="xlarge">
              <Box align="center">
                <Avatar>
                  <StaticImage src="../images/alionka.png" alt="aliona" placeholder="none" quality={90} />
                </Avatar>
                <Title level="4">Aliona Shybko</Title>
                <p>Web designer</p>
              </Box>

              <Box>
                <Title level={6}>Skills</Title>
                <Box gap="large" justify="between" className="skills-row">
                  <Skills>
                    <SkillItem label="Figma" points={9} />
                    <SkillItem label="Adobe Photoshop" points={9} />
                    <SkillItem label="Adobe Illustrator" points={9} />
                  </Skills>

                  <Skills>
                    <SkillItem label="Adobe Premiere Pro" points={9} />
                    <SkillItem label="Adobe InDesign" points={9} />
                    <SkillItem label="Canva" points={9} />
                  </Skills>
                </Box>
              </Box>
            </LeftBox>
            <RightBox gap="large">
              <Box width="full">
                <Box direction="row" className="info-item">
                  <div className="icon">
                    <PinIcon />
                  </div>
                  Brno, Czech republic
                </Box>
                <Box direction="row" className="info-item">
                  <div className="icon">
                    <PhoneIcon />
                  </div>
                  <Box direction="column">+420 775 088 618</Box>
                </Box>
                <Box direction="row" className="info-item">
                  <div className="icon">
                    <EnvelopeIcon />
                  </div>
                  <Box direction="column">
                    design&#64;&#116;it&#97;ndesi&#103;n&#46;eu
                    <br />
                    al&#101;n&#111;&#99;hka&#46;sh&#105;bko&#64;&#103;mail&#46;&#99;om
                  </Box>
                </Box>
              </Box>
              <Box>
                <Title level={6}>About me</Title>
                <Paragraph>
                  Although the design is not my main specialization, I always try to deliver work on a professional
                  level. The creation of clean, modern visuals which are nice to look at, but functional and
                  user-friendly at the same time, brings me joy. The most important for me is customers’ satisfaction.
                </Paragraph>
              </Box>
              <Box>
                <Title level={6}>Education</Title>
                <Paragraph className="education-item">
                  FBE Mendelu Brno, Economics and Management
                  <span>
                    Bachelor’s degree
                    <br />
                    <br />
                    2016 – 2019
                  </span>
                </Paragraph>
              </Box>
            </RightBox>
          </BioCard>
        </Box>
      </Container>
    </StyledSection>
  </AboutPage>
);

export default About;
