import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Box } from "grommet";
import theme from "../../config/theme";

const ItemWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 12px;
  }

  label {
    display: block;
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 10px;
  }

  div {
    display: flex;

    span {
      background-color: #e4e3e3;
      width: 7px;
      height: 7px;
      border-radius: 50%;

      &:not(:last-child) {
        margin-right: 4px;
      }

      &.active {
        background-color: ${theme.colors.brand};
      }
    }
  }
`;

export const Skills = styled(Box)`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  margin-bottom: 10px;
  width: 100%;

  @media (min-width: 340px) {
    flex-basis: 50%;
  }
`;

export const SkillItem = ({ label, points }) => {
  const maxPoints = 10;

  const renderDots = () => {
    const dots = [];

    for (let i = 0; i < maxPoints; i++) {
      dots.push(<span key={i} className={`${i < points ? "active" : ""}`} />);
    }
    return dots;
  };

  return (
    <ItemWrapper>
      <label>{label}</label>
      <div>{renderDots()}</div>
    </ItemWrapper>
  );
};

SkillItem.propTypes = {
  label: PropTypes.string,
  points: PropTypes.number
};
